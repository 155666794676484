var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"transactions"},[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',{attrs:{"align":"center"}},[_c('v-text-field',{staticClass:"pt-0",attrs:{"placeholder":"Search for transaction"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-spacer'),_c('v-btn',{attrs:{"small":"","outlined":"","color":"#788088","loading":_vm.loading},on:{"click":_vm.refresh}},[_vm._v("Refresh")])],1)],1),_c('v-data-table',{staticClass:"transactions-table elevation-1 card",attrs:{"headers":_vm.headers,"items":_vm.filteredTransactions,"page":_vm.page,"items-per-page":_vm.itemsPerPage,"loading":_vm.loading,"hide-default-footer":""},on:{"update:page":function($event){_vm.page=$event},"page-count":function($event){_vm.pageCount = $event}},scopedSlots:_vm._u([{key:"item._user.username",fn:function(ref){
var item = ref.item;
return [(item._user)?_c('v-btn',{attrs:{"link":"","to":("/dashboard/user/" + (item._user._id)),"color":"#5367FF","small":"","text":""}},[_vm._v(_vm._s(item._user.username))]):_vm._e()]}},{key:"item.type",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm._f("capitalize")(item.type)))]}},{key:"item.state",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":_vm.getColor(item.state),"label":"","dark":""}},[_vm._v(_vm._s(_vm._f("convertTransactionState")(item.state)))])]}},{key:"item.siteValue",fn:function(ref){
var item = ref.item;
return [_vm._v("$"+_vm._s(item.siteValue.toFixed(2)))]}},{key:"item.created",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm._f("dateFormat")(new Date(item.created),'DD.MM.YYYY HH:mm:ss')))]}},{key:"item.txid",fn:function(ref){
var item = ref.item;
return [(item.currency == 'BTC')?_c('v-tooltip',{attrs:{"bottom":"","color":"#272b2f"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"warning","small":"","text":"","link":"","href":("https://www.blockchain.com/btc/tx/" + (item.txid)),"target":"_blank"}},'v-btn',attrs,false),on),[_vm._v("Explorer")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.txid))])]):_vm._e(),(item.currency == 'ETH')?_c('v-tooltip',{attrs:{"bottom":"","color":"#272b2f"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"warning","small":"","text":"","link":"","href":("https://etherscan.io/tx/0x" + (item.txid)),"target":"_blank"}},'v-btn',attrs,false),on),[_vm._v("Explorer")])]}}],null,true)},[_c('span',[_vm._v("0x"+_vm._s(item.txid))])]):_vm._e(),(item.currency == 'LTC')?_c('v-tooltip',{attrs:{"bottom":"","color":"#272b2f"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"warning","small":"","text":"","link":"","href":("https://live.blockcypher.com/ltc/tx/" + (item.txid)),"target":"_blank"}},'v-btn',attrs,false),on),[_vm._v("Explorer")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.txid))])]):_vm._e()]}}])}),_c('v-pagination',{staticClass:"mt-3",attrs:{"color":"#272B2F","length":_vm.pageCount},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }