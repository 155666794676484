var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"vips"},[_c('v-data-table',{staticClass:"users-table elevation-1 card",attrs:{"headers":_vm.headers,"items":_vm.users,"page":_vm.page,"items-per-page":_vm.itemsPerPage,"hide-default-footer":""},on:{"update:page":function($event){_vm.page=$event},"page-count":function($event){_vm.pageCount = $event}},scopedSlots:_vm._u([{key:"item.avatar",fn:function(ref){
var item = ref.item;
return [_c('img',{staticClass:"avatar my-3",attrs:{"src":item.avatar,"alt":"USER AVATAR"}})]}},{key:"item.vip.rank",fn:function(ref){
var item = ref.item;
return [_c('span',{style:({color: item.vip.color})},[_vm._v(_vm._s(item.vip.rank))])]}},{key:"item.xp.current",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(((item.xp.current) + " / " + (item.xp.target))))]}},{key:"item.profile",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"px-5",attrs:{"color":"#788088","small":"","outlined":"","rounded":"","link":"","to":("/user/" + (item.uid) + "/" + (item.username))}},[_vm._v("User")])]}}])}),_c('v-pagination',{staticClass:"mt-3",attrs:{"color":"#272B2F","length":_vm.pageCount},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }