var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"users"},[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',{attrs:{"align":"center"}},[_c('v-text-field',{staticClass:"pt-0",attrs:{"placeholder":"Search for user"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-spacer'),_c('v-btn',{attrs:{"small":"","outlined":"","color":"#788088","loading":_vm.loading},on:{"click":_vm.refresh}},[_vm._v("Refresh")])],1)],1),_c('v-data-table',{staticClass:"users-table elevation-1 card",attrs:{"headers":_vm.headers,"items":_vm.filteredUsers,"page":_vm.page,"items-per-page":_vm.itemsPerPage,"loading":_vm.loading,"hide-default-footer":""},on:{"update:page":function($event){_vm.page=$event},"page-count":function($event){_vm.pageCount = $event}},scopedSlots:_vm._u([{key:"item.avatar",fn:function(ref){
var item = ref.item;
return [_c('img',{staticClass:"avatar my-3",attrs:{"src":item.avatar,"alt":"USER AVATAR"}})]}},{key:"item.rank",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm._f("convertRank")(item.rank)))]}},{key:"item.wallet",fn:function(ref){
var item = ref.item;
return [_vm._v("$"+_vm._s(item.wallet.toFixed(2)))]}},{key:"item.betsLocked",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(item.betsLocked ? 'Locked' : 'Unlocked'))]}},{key:"item.transactionsLocked",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(item.transactionsLocked ? 'Locked' : 'Unlocked'))]}},{key:"item.profile",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"px-5",attrs:{"color":"#788088","small":"","outlined":"","rounded":"","link":"","to":("/dashboard/user/" + (item._id))}},[_vm._v("User")])]}}])}),_c('v-pagination',{staticClass:"mt-3",attrs:{"color":"#272B2F","length":_vm.pageCount},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }