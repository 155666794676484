var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"transactions"},[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',{attrs:{"align":"center"}},[_c('v-text-field',{staticClass:"pt-0",attrs:{"placeholder":"Search for withdrawal"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-spacer'),_c('v-btn',{attrs:{"small":"","outlined":"","color":"#788088","loading":_vm.loading},on:{"click":_vm.refresh}},[_vm._v("Refresh")])],1)],1),_c('v-data-table',{staticClass:"transactions-table elevation-1 card",attrs:{"headers":_vm.headers,"items":_vm.filteredTransactions,"page":_vm.page,"items-per-page":_vm.itemsPerPage,"loading":_vm.loading,"hide-default-footer":""},on:{"update:page":function($event){_vm.page=$event},"page-count":function($event){_vm.pageCount = $event}},scopedSlots:_vm._u([{key:"item._user.username",fn:function(ref){
var item = ref.item;
return [(item._user)?_c('v-btn',{attrs:{"link":"","to":("/dashboard/user/" + (item._user._id)),"color":"#5367FF","small":"","text":""}},[_vm._v(_vm._s(item._user.username))]):_vm._e()]}},{key:"item.siteValue",fn:function(ref){
var item = ref.item;
return [_vm._v("$"+_vm._s(item.siteValue.toFixed(2)))]}},{key:"item.created",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm._f("dateFormat")(new Date(item.created),'DD.MM.YYYY HH:mm:ss')))]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{attrs:{"offset-y":"","top":"","nudge-left":"55","nudge-top":"10"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"success","outlined":"","small":""}},on),[_vm._v("Confirm")])]}}],null,true)},[_c('v-card',{attrs:{"color":"card"}},[_c('v-card-text',{staticClass:"py-2 text-center"},[_vm._v("Please confirm this action")]),_c('v-card-actions',{staticClass:"pt-1 pb-2"},[_c('v-btn',{staticClass:"mx-auto",attrs:{"color":"success","small":"","text":""},on:{"click":function($event){return _vm.confirm(item._id)}}},[_vm._v("Confirm")])],1)],1)],1),_c('v-menu',{attrs:{"offset-y":"","top":"","nudge-left":"55","nudge-top":"10"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ml-3",attrs:{"color":"error","outlined":"","small":""}},on),[_vm._v("Cancel")])]}}],null,true)},[_c('v-card',{attrs:{"color":"card"}},[_c('v-card-text',{staticClass:"py-2 text-center"},[_vm._v("Please confirm this action")]),_c('v-card-actions',{staticClass:"pt-1 pb-2"},[_c('v-btn',{staticClass:"mx-auto",attrs:{"color":"error","small":"","text":""},on:{"click":function($event){return _vm.cancel(item._id)}}},[_vm._v("Confirm")])],1)],1)],1)]}}])}),_c('v-pagination',{staticClass:"mt-3",attrs:{"color":"#272B2F","length":_vm.pageCount},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }